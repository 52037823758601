.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 5px;
    border-radius: 4px;
    outline: none;
    width: 250px;
    max-width: 100%;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0); /* Adjust opacity as needed */
  }
  
  .options {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Small gap between buttons */
  }
  
  .option-btn {
    padding: 0; 
    margin: 0;
    background: none; 
    color: inherit; 
    border: none; 
    cursor: pointer; 
    text-align: left; 
  }
  
  .ordered-speaker-list {
    margin-top: 5px;
  }
  
  .ordered-speaker-list ul {
    list-style: none;
    padding: 0;
  }
  
  .ordered-speaker-list li {
    padding: 5px 0;
    cursor: pointer; /* Add cursor to indicate hoverable item */
    position: relative; /* Ensure relative positioning for submodal */
  }
  
  .custom-tooltip {
    position: absolute;
    top: 50%+50px; 
    left: calc(100% + 1px); 
    transform: translateY(-50%); 
    background: white;
    padding: 2px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
    z-index: 1000; 
    white-space: nowrap;
    transition: visibility 1s;
  }
  